import React from 'react'
import { Heading } from "./common/Heading"
import contact from "../assets/img/contact.jpg" 
function TermsBanner() {
  return (
    <section className=' contactBack'>
        <div className='contactContainerService  '>
       
            <>
               <img  src={ contact}  />
            
               
            
              
            </>
         
        </div>
      </section>
  )
}

export default TermsBanner;